import React from 'react';
import { Search, Wand2, PlayCircle, Share2 } from 'lucide-react';
import { VideoCreator } from './VideoCreator';
import { PublishInterface } from './PublishInterface';

export function HowItWorks() {
  return (
    <section className="bg-black/50 py-32">
      <div className="container mx-auto px-6">
        <div className="text-center mb-24">
          <h2 className="text-4xl font-bold mb-6">How It Works</h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Create viral-worthy videos in minutes, not hours. No video editing skills required.
          </p>
        </div>

        <div className="grid gap-12 max-w-6xl mx-auto">
          {/* First Row */}
          <div className="grid lg:grid-cols-2 gap-8">
            {/* Step 1: Search */}
            <div className="bg-purple-950/20 rounded-3xl p-8 lg:p-12">
              <div className="flex items-start justify-between mb-8">
                <span className="text-8xl font-bold text-purple-500/20">1</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Search Viral TikTok Videos</h3>
              <p className="text-gray-400 mb-6">
                Input a topic and hit "Search" to find viral TikTok videos
              </p>
              
              <div className="space-y-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Children stories"
                    className="w-full bg-black/30 border border-purple-500/20 rounded-lg px-4 py-3 text-white placeholder-gray-500 focus:border-purple-500 transition-colors"
                  />
                  <button className="absolute right-2 top-1/2 -translate-y-1/2 bg-purple-500 hover:bg-purple-600 text-white px-4 py-1.5 rounded-lg text-sm transition-colors">
                    Search
                  </button>
                </div>
                
                <div className="flex flex-wrap gap-2">
                  {['Love stories', 'Horror shorts', 'Cultural folktales', 'Fantasy stories'].map((tag) => (
                    <span key={tag} className="bg-purple-500/10 text-purple-300 px-3 py-1 rounded-full text-sm">
                      {tag}
                    </span>
                  ))}
                </div>

                <div className="grid grid-cols-3 gap-3">
                  {[
                    'https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=300&h=400&fit=crop',
                    'https://images.unsplash.com/photo-1552058544-f2b08422138a?w=300&h=400&fit=crop',
                    'https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=300&h=400&fit=crop'
                  ].map((src, i) => (
                    <div key={i} className="relative group cursor-pointer">
                      <img
                        src={src}
                        alt="Video thumbnail"
                        className="w-full aspect-[3/4] object-cover rounded-lg"
                        loading="lazy"
                      />
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg" />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Step 2: Generate Script */}
            <div className="bg-purple-950/20 rounded-3xl p-8 lg:p-12">
              <div className="flex items-start justify-between mb-8">
                <span className="text-8xl font-bold text-purple-500/20">2</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Generate Your Script</h3>
              <p className="text-gray-400 mb-6">
                Let AI write engaging scripts based on viral content
              </p>

              <div className="space-y-4">
                <div className="bg-black/30 rounded-lg p-6 border border-purple-500/20">
                  <div className="flex justify-between items-start mb-4">
                    <h4 className="text-lg font-semibold">Script Preview</h4>
                    <button className="px-3 py-1.5 text-sm bg-green-500/20 text-green-300 rounded-lg flex items-center gap-1 hover:bg-green-500/30 transition-colors">
                      <Wand2 className="w-4 h-4" />
                      Generate
                    </button>
                  </div>
                  <p className="text-gray-400 text-sm leading-relaxed">
                    Hidden beneath your garden lies an ancient portal...
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="grid lg:grid-cols-2 gap-8">
            {/* Step 3: Create Video */}
            <div className="bg-purple-950/20 rounded-3xl p-8 lg:p-12">
              <div className="flex items-start justify-between mb-8">
                <span className="text-8xl font-bold text-purple-500/20">3</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Create Your Viral Video</h3>
              <p className="text-gray-400 mb-6">
                Transform your script into an engaging video
              </p>
              <VideoCreator />
            </div>

            {/* Step 4: Publish */}
            <div className="bg-purple-950/20 rounded-3xl p-8 lg:p-12">
              <div className="flex items-start justify-between mb-8">
                <span className="text-8xl font-bold text-purple-500/20">4</span>
              </div>
              <h3 className="text-2xl font-bold mb-4">Share Everywhere</h3>
              <p className="text-gray-400 mb-6">
                Publish directly to your favorite platforms
              </p>
              <PublishInterface />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}