import { useEffect, useState } from 'react';

export type Currency = 'USD' | 'INR';

export interface PricingConfig {
  currency: Currency;
  symbol: string;
  multiplier: number;
}

const PRICING_CONFIGS: Record<Currency, PricingConfig> = {
  USD: {
    currency: 'USD',
    symbol: '$',
    multiplier: 1
  },
  INR: {
    currency: 'INR',
    symbol: '₹',
    multiplier: 83 // Current approximate exchange rate
  }
};

export const detectUserCurrency = async (): Promise<Currency> => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    return data.country === 'IN' ? 'INR' : 'USD';
  } catch (error) {
    console.error('Error detecting location:', error);
    return 'USD';
  }
};

export const formatPrice = (price: number | string, config: PricingConfig): string => {
  const numericPrice = typeof price === 'string' ? parseFloat(price) : price;
  if (price === 'Free') return 'Free';
  const finalPrice = Math.round(numericPrice * config.multiplier);
  return `${config.symbol}${finalPrice}`;
};