import React from 'react';
import { Share2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

// Social media icons
function TikTokIcon({ className = "w-6 h-6" }) {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="currentColor">
      <path d="M19.321 5.562a5.124 5.124 0 0 1-3.585-1.476A5.124 5.124 0 0 1 14.26.5h-4.229v15.406c0 1.985-1.61 3.595-3.595 3.595s-3.595-1.61-3.595-3.595 1.61-3.595 3.595-3.595c.253 0 .5.027.74.076v-4.337c-.247-.018-.493-.036-.74-.036C2.91 7.914 0 10.824 0 14.35s2.91 6.436 6.436 6.436 6.436-2.91 6.436-6.436V8.89a9.335 9.335 0 0 0 5.79 1.977V6.523"/>
    </svg>
  );
}

function InstagramIcon({ className = "w-6 h-6" }) {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="currentColor">
      <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
    </svg>
  );
}

function YoutubeIcon({ className = "w-6 h-6" }) {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="currentColor">
      <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
    </svg>
  );
}

export function PublishInterface() {
  const navigate = useNavigate();

  const handlePublish = () => {
    navigate('/waitlist');
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-3 mb-4">
        {[
          'https://images.unsplash.com/photo-1580477667995-2b94f01c9516?fit=crop&w=300&h=400&q=80',
          'https://images.unsplash.com/photo-1578632767115-351597cf2477?fit=crop&w=300&h=400&q=80'
        ].map((src, i) => (
          <div key={i} className="relative group cursor-pointer overflow-hidden" onClick={handlePublish}>
            <img
              src={src}
              alt="Content preview"
              className="w-full aspect-[9/16] object-cover rounded-lg transform group-hover:scale-105 transition-transform duration-300"
              loading="lazy"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent rounded-lg opacity-0 group-hover:opacity-100 transition-opacity" />
          </div>
        ))}
      </div>

      <div className="grid grid-cols-3 gap-3">
        <div className="flex items-center justify-center gap-2 p-4 bg-purple-900/20 rounded-lg hover:bg-purple-900/30 transition-colors cursor-pointer" onClick={handlePublish}>
          <TikTokIcon className="w-8 h-8 text-[#00f2ea]" />
        </div>
        <div className="flex items-center justify-center gap-2 p-4 bg-purple-900/20 rounded-lg hover:bg-purple-900/30 transition-colors cursor-pointer" onClick={handlePublish}>
          <InstagramIcon className="w-8 h-8 text-[#E4405F]" />
        </div>
        <div className="flex items-center justify-center gap-2 p-4 bg-purple-900/20 rounded-lg hover:bg-purple-900/30 transition-colors cursor-pointer" onClick={handlePublish}>
          <YoutubeIcon className="w-8 h-8 text-[#FF0000]" />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-3">
        <button 
          onClick={handlePublish}
          className="flex items-center justify-center gap-2 p-3 bg-purple-900/20 rounded-lg hover:bg-purple-900/30 transition-colors"
        >
          <Share2 className="w-4 h-4" />
          <span className="text-sm">Schedule Post</span>
        </button>
        <button 
          onClick={handlePublish}
          className="flex items-center justify-center gap-2 p-3 bg-purple-900/20 rounded-lg hover:bg-purple-900/30 transition-colors"
        >
          <Share2 className="w-4 h-4" />
          <span className="text-sm">Download Video</span>
        </button>
      </div>
    </div>
  );
}