import React from 'react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { Check } from 'lucide-react';

function PricingPage() {
  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-20">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-6xl font-bold mb-6">Simple, Transparent Pricing</h1>
          <p className="text-xl text-gray-300">Choose the perfect plan for your video creation needs</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {[
            {
              name: "Starter",
              price: "Free",
              description: "Perfect for trying out our platform",
              features: [
                "5 AI videos per month",
                "720p resolution",
                "Basic editing tools",
                "Standard support"
              ]
            },
            {
              name: "Pro",
              price: "$29",
              description: "Most popular for content creators",
              features: [
                "50 AI videos per month",
                "1080p resolution",
                "Advanced editing tools",
                "Priority support",
                "Custom branding",
                "Analytics dashboard"
              ]
            },
            {
              name: "Business",
              price: "$99",
              description: "For teams and agencies",
              features: [
                "Unlimited AI videos",
                "4K resolution",
                "Team collaboration",
                "24/7 priority support",
                "API access",
                "Custom integrations",
                "Dedicated account manager"
              ]
            }
          ].map((plan) => (
            <div key={plan.name} className="bg-purple-900/20 rounded-2xl p-8 border border-purple-500/20">
              <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
              <div className="text-3xl font-bold mb-4">
                {plan.price}
                {plan.price !== "Free" && <span className="text-lg font-normal text-gray-400">/mo</span>}
              </div>
              <p className="text-gray-400 mb-6">{plan.description}</p>
              <ul className="space-y-4 mb-8">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex items-center gap-2">
                    <Check className="w-5 h-5 text-purple-400" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <button className="w-full bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 px-6 py-3 rounded-full font-medium transition-all duration-300">
                Get Started
              </button>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PricingPage;