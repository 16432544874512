import React, { createContext, useContext, useEffect, useState } from 'react';
import { Currency, PricingConfig, detectUserCurrency } from '../utils/pricing';

interface PricingContextType {
  currency: Currency;
  config: PricingConfig;
}

const PricingContext = createContext<PricingContextType | undefined>(undefined);

const PRICING_CONFIGS: Record<Currency, PricingConfig> = {
  USD: {
    currency: 'USD',
    symbol: '$',
    multiplier: 1
  },
  INR: {
    currency: 'INR',
    symbol: '₹',
    multiplier: 83
  }
};

export function PricingProvider({ children }: { children: React.ReactNode }) {
  const [currency, setCurrency] = useState<Currency>('USD');

  useEffect(() => {
    const initCurrency = async () => {
      const detectedCurrency = await detectUserCurrency();
      setCurrency(detectedCurrency);
    };
    initCurrency();
  }, []);

  const value = {
    currency,
    config: PRICING_CONFIGS[currency]
  };

  return (
    <PricingContext.Provider value={value}>
      {children}
    </PricingContext.Provider>
  );
}

export function usePricing() {
  const context = useContext(PricingContext);
  if (context === undefined) {
    throw new Error('usePricing must be used within a PricingProvider');
  }
  return context;
}