import React from 'react';
import { Zap, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export function CTA() {
  return (
    <section className="bg-gradient-to-r from-purple-900 to-pink-900 py-24">
      <div className="container mx-auto px-6 text-center">
        <div className="inline-flex items-center gap-2 bg-white/10 px-4 py-2 rounded-full mb-8">
          <Zap className="w-4 h-4 text-yellow-400" />
          <span>Limited Time Offer</span>
        </div>
        <h2 className="text-4xl font-bold mb-6">Join the Waitlist Today</h2>
        <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
          Be among the first 100 users to get 100 free AI-generated videos!
        </p>
        <Link 
          to="/waitlist"
          className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 px-8 py-4 rounded-full font-semibold text-lg inline-flex items-center gap-2 transition-all duration-300 transform hover:scale-[1.02] hover:shadow-[0_0_20px_rgba(168,85,247,0.3)]"
        >
          <span>Join Waitlist Now</span>
          <ArrowRight className="w-5 h-5" />
        </Link>
      </div>
    </section>
  );
}