import React from 'react';
import { Navbar } from '../components/Navbar';
import { Hero } from '../components/Hero';
import { HowItWorks } from '../components/Features/HowItWorks';
import { SocialProof } from '../components/SocialProof';
import { CTA } from '../components/CTA';
import { Footer } from '../components/Footer';
import { WaitlistBanner } from '../components/WaitlistBanner';
import { IntegratedPricing } from '../components/IntegratedPricing';

function HomePage() {
  return (
    <>
      <WaitlistBanner />
      <Navbar />
      <main>
        <Hero />
        <HowItWorks />
        <SocialProof />
        <IntegratedPricing />
        <CTA />
      </main>
      <Footer />
    </>
  );
}

export default HomePage;