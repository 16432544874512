import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { Link } from 'react-router-dom';
import { usePricing } from '../contexts/PricingContext';
import { formatPrice } from '../utils/pricing';

export function IntegratedPricing() {
  const [isYearly, setIsYearly] = useState(false);
  const { config } = usePricing();

  const plans = [
    {
      name: "Free",
      price: "Free",
      description: "Perfect for trying out our platform",
      features: [
        "5 AI videos per month",
        "720p resolution",
        "Basic editing tools",
        "Standard support"
      ]
    },
    {
      name: "Pro",
      price: isYearly ? "90" : "15",
      description: "Most popular for content creators",
      features: [
        "50 AI videos per month",
        "1080p resolution",
        "Advanced editing tools",
        "Priority support",
        "Custom branding",
        "Analytics dashboard"
      ],
      popular: true
    },
    {
      name: "Premium",
      price: isYearly ? "294" : "49",
      description: "For professional creators",
      features: [
        "300 AI videos per month",
        "4K resolution",
        "Priority support",
        "Custom branding",
        "Advanced analytics",
        "Dedicated account manager",
        "1-on-1 onboarding"
      ]
    }
  ];

  return (
    <section id="pricing" className="py-24 bg-gradient-to-b from-purple-900/20 to-black">
      <div className="container mx-auto px-4 md:px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">Simple, Transparent Pricing</h2>
          <p className="text-xl text-gray-300 mb-8">Choose the perfect plan for your video creation needs</p>
          
          <div className="inline-flex items-center bg-purple-900/30 p-1 rounded-full">
            <button
              className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                !isYearly ? 'bg-purple-500 text-white' : 'text-gray-400 hover:text-white'
              }`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </button>
            <button
              className={`px-6 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                isYearly ? 'bg-purple-500 text-white' : 'text-gray-400 hover:text-white'
              }`}
              onClick={() => setIsYearly(true)}
            >
              Yearly
              <span className="ml-2 text-xs bg-green-500/20 text-green-300 px-2 py-0.5 rounded-full">
                6 months free
              </span>
            </button>
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {plans.map((plan) => (
            <div 
              key={plan.name}
              className={`relative bg-purple-900/20 rounded-2xl p-8 border ${
                plan.popular 
                  ? 'border-purple-500 shadow-lg shadow-purple-500/20' 
                  : 'border-purple-500/20'
              }`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2 bg-gradient-to-r from-purple-500 to-pink-500 px-4 py-1 rounded-full text-sm font-medium">
                  Most Popular
                </div>
              )}

              <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
              <div className="text-3xl font-bold mb-4">
                {plan.price === "Free" ? (
                  "Free"
                ) : (
                  <>
                    {formatPrice(plan.price, config)}
                    <span className="text-lg font-normal text-gray-400">
                      /{isYearly ? 'year' : 'mo'}
                    </span>
                  </>
                )}
              </div>
              <p className="text-gray-400 mb-6">{plan.description}</p>
              
              <ul className="space-y-4 mb-8">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex items-center gap-2">
                    <Check className="w-5 h-5 text-purple-400" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>

              <div className="space-y-2">
                <Link 
                  to="/waitlist"
                  className={`block w-full text-center px-6 py-3 rounded-full font-medium transition-all duration-300 ${
                    plan.popular
                      ? 'bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600'
                      : 'bg-purple-500/20 hover:bg-purple-500/30'
                  }`}
                >
                  {plan.price === "Free" ? "Start Free" : "Get Started"}
                </Link>
                <p className="text-xs text-center text-gray-400">No credit card required</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}