import React from 'react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { FreeTools } from '../components/Tools/FreeTools';
import { WaitlistBanner } from '../components/WaitlistBanner';

function ToolsPage() {
  return (
    <>
      <WaitlistBanner />
      <Navbar />
      <main>
        <FreeTools />
      </main>
      <Footer />
    </>
  );
}

export default ToolsPage;