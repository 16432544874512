import React from 'react';
import { Video, Music, Wand2, Sparkles, Subtitles, Youtube, Film, Hash } from 'lucide-react';
import { Link } from 'react-router-dom';

const tools = [
  {
    icon: <Video className="w-6 h-6" />,
    title: "Video to Shorts Converter",
    description: "Convert any long video into viral-worthy shorts automatically",
    category: "Video Creation",
    searchVolume: "800 monthly searches",
    tags: ["Free", "Popular"],
    path: "/tools/video-to-shorts"
  },
  {
    icon: <Hash className="w-6 h-6" />,
    title: "Hashtag Generator",
    description: "Generate trending hashtags for your short-form videos",
    category: "Growth",
    searchVolume: "1.2K monthly searches",
    tags: ["Free", "New"],
    path: "/tools/hashtag-generator"
  },
  {
    icon: <Music className="w-6 h-6" />,
    title: "AI Music Video Creator",
    description: "Create stunning music videos with AI-generated visuals",
    category: "Music Videos",
    searchVolume: "5K monthly searches",
    tags: ["Free"],
    path: "/waitlist"
  },
  {
    icon: <Film className="w-6 h-6" />,
    title: "Audio Visualizer",
    description: "Generate captivating visual effects that sync with your music",
    category: "Music Videos",
    searchVolume: "2K monthly searches",
    tags: ["Free"],
    path: "/waitlist"
  },
  {
    icon: <Subtitles className="w-6 h-6" />,
    title: "Auto Caption Generator",
    description: "Add engaging captions to your videos automatically",
    category: "Video Enhancement",
    searchVolume: "4K monthly searches",
    tags: ["Free"],
    path: "/waitlist"
  },
  {
    icon: <Youtube className="w-6 h-6" />,
    title: "YouTube Shorts Creator",
    description: "Convert long videos into engaging YouTube Shorts",
    category: "Video Creation",
    searchVolume: "3K monthly searches",
    tags: ["Free"],
    path: "/waitlist"
  }
];

export function FreeTools() {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-purple-950/20">
      <div className="container mx-auto">
        <div className="grid grid-cols-5 gap-8">
          {/* Left empty space (20%) */}
          <div className="hidden lg:block" />
          
          {/* Main content (60%) */}
          <div className="col-span-5 lg:col-span-3 px-4 lg:px-0">
            <div className="text-center mb-16">
              <div className="inline-flex items-center gap-2 bg-purple-500/10 px-4 py-2 rounded-full mb-8">
                <Sparkles className="w-4 h-4 text-purple-400" />
                <span className="text-purple-300">Free AI Tools</span>
              </div>
              <h1 className="text-4xl md:text-6xl font-bold mb-6">Free AI Video Tools</h1>
              <p className="text-xl text-gray-300">
                Create amazing videos with our collection of free AI tools. No credit card required.
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
              {tools.map((tool) => (
                <Link 
                  key={tool.title}
                  to={tool.path}
                  className="bg-purple-900/20 rounded-xl p-6 border border-purple-500/20 hover:border-purple-500/40 transition-all group"
                >
                  <div className="flex justify-between items-start mb-4">
                    <div className="bg-purple-500/10 p-3 rounded-lg group-hover:scale-110 transition-transform">
                      {tool.icon}
                    </div>
                    <div className="flex gap-1">
                      {tool.tags.map((tag) => (
                        <span 
                          key={tag}
                          className={`px-2 py-0.5 rounded-full text-xs font-medium ${
                            tag === 'Popular' 
                              ? 'bg-purple-500/20 text-purple-300'
                              : tag === 'New'
                              ? 'bg-green-500/20 text-green-300'
                              : 'bg-blue-500/20 text-blue-300'
                          }`}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>

                  <h3 className="text-lg font-bold mb-2">{tool.title}</h3>
                  <p className="text-sm text-gray-400 mb-4">{tool.description}</p>

                  <div className="space-y-3">
                    <div className="flex items-center gap-2 text-xs text-gray-500">
                      <span className="px-2 py-0.5 bg-purple-500/10 rounded-full text-purple-300">
                        {tool.category}
                      </span>
                      <span className="text-gray-400">•</span>
                      <span className="text-gray-400">{tool.searchVolume}</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>

          {/* Right empty space (20%) */}
          <div className="hidden lg:block" />
        </div>
      </div>
    </section>
  );
}