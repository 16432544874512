import React from 'react';
import { Scissors } from 'lucide-react';

export function Logo({ className = "" }) {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <div className="relative">
        <Scissors className="w-6 h-6 text-purple-400 transform -rotate-45" />
        <div className="absolute -top-1 -right-1 w-2 h-2 bg-pink-500 rounded-full animate-pulse" />
      </div>
      <span className="font-bold">
        Genclips<span className="text-purple-400">.ai</span>
      </span>
    </div>
  );
}