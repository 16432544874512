import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import { Video, Sparkles, TrendingUp, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

function ViralFormulaGuide() {
  return (
    <>
      <Helmet>
        <title>Viral Video Formula: Create Videos That Get Millions of Views | Genclips.ai</title>
        <meta 
          name="description" 
          content="Learn the proven formula for creating viral short-form videos. Discover the secrets behind hooks, storytelling, and engagement that top creators use."
        />
        <meta property="og:title" content="Viral Video Formula Guide | Genclips.ai" />
        <meta property="og:description" content="Master the art of creating viral videos with our comprehensive guide. Learn from top creators and start getting millions of views." />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="https://genclips.ai/guides/viral" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="keywords" content="viral videos, tiktok formula, video marketing, content creation, social media strategy" />
      </Helmet>

      <Navbar />
      <div className="container mx-auto px-4 py-20">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <div className="inline-flex items-center gap-2 bg-purple-500/10 px-4 py-2 rounded-full mb-6">
              <TrendingUp className="w-4 h-4 text-purple-400" />
              <span className="text-purple-300">Guide</span>
            </div>
            <h1 className="text-4xl md:text-6xl font-bold mb-6">The Viral Video Formula</h1>
            <p className="text-xl text-gray-300">
              Learn how to create videos that get millions of views
            </p>
          </div>

          <div className="prose prose-invert max-w-none">
            <div className="bg-purple-900/20 rounded-2xl p-8 mb-8">
              <h2 className="text-2xl font-bold mb-4">The Hook (First 3 Seconds)</h2>
              <p className="text-gray-300 mb-4">
                The first 3 seconds of your video are crucial. Learn how to create hooks that stop the scroll and capture attention instantly.
              </p>
              <Link 
                to="/waitlist"
                className="inline-flex items-center gap-2 text-purple-400 hover:text-purple-300 transition-colors"
              >
                Try AI Hook Generator
                <ArrowRight className="w-4 h-4" />
              </Link>
            </div>

            <div className="grid md:grid-cols-2 gap-8 mb-8">
              <div className="bg-purple-900/20 rounded-2xl p-8">
                <Sparkles className="w-8 h-8 text-purple-400 mb-4" />
                <h3 className="text-xl font-bold mb-4">Pattern Interrupts</h3>
                <p className="text-gray-300">
                  Learn the psychological triggers that make viewers stop scrolling and watch your entire video.
                </p>
              </div>
              <div className="bg-purple-900/20 rounded-2xl p-8">
                <Video className="w-8 h-8 text-purple-400 mb-4" />
                <h3 className="text-xl font-bold mb-4">Story Structure</h3>
                <p className="text-gray-300">
                  Master the art of storytelling in short-form content to keep viewers engaged till the end.
                </p>
              </div>
            </div>

            <div className="bg-purple-900/20 rounded-2xl p-8">
              <h2 className="text-2xl font-bold mb-4">Ready to Create Viral Videos?</h2>
              <p className="text-gray-300 mb-6">
                Join the waitlist to get early access to our AI video creation tools and start creating viral content today.
              </p>
              <Link
                to="/waitlist"
                className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 px-6 py-3 rounded-lg text-white font-medium transition-colors"
              >
                Join Waitlist
                <ArrowRight className="w-4 h-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ViralFormulaGuide;