import React from 'react';
import { Film } from 'lucide-react';

export function VideoCreator() {
  const styles = ['Cinematic', 'Vlog', 'Tutorial', 'Story'];
  
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-3">
        {[
          'https://images.unsplash.com/photo-1599577180758-69c952d78d84?fit=crop&w=300&h=400&q=80',
          'https://images.unsplash.com/photo-1531259683007-016a7b628fc3?fit=crop&w=300&h=400&q=80',
          'https://images.unsplash.com/photo-1608889476561-6242cfdbf622?fit=crop&w=300&h=400&q=80'
        ].map((src, i) => (
          <div key={i} className="relative group">
            <img
              src={src}
              alt="Video style"
              className="w-full aspect-[3/4] object-cover rounded-lg"
              loading="lazy"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent rounded-lg opacity-0 group-hover:opacity-100 transition-opacity" />
          </div>
        ))}
      </div>

      <div className="flex flex-wrap gap-2">
        {styles.map((style, index) => (
          <button
            key={index}
            className="px-3 py-1 rounded-full text-xs font-medium bg-purple-500/10 hover:bg-purple-500/20 transition-colors"
          >
            {style}
          </button>
        ))}
      </div>

      <div className="space-y-2">
        <div className="relative h-1.5 bg-purple-900/30 rounded-full overflow-hidden">
          <div className="absolute left-0 top-0 h-full w-2/3 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full" />
        </div>
        <div className="flex items-center gap-2 text-xs text-purple-300">
          <Film className="w-4 h-4" />
          <span>Processing video...</span>
        </div>
      </div>
    </div>
  );
}