import React from 'react';
import { Gift, X } from 'lucide-react';
import { Link } from 'react-router-dom';

export function WaitlistBanner() {
  const [isVisible, setIsVisible] = React.useState(true);

  if (!isVisible) return null;

  return (
    <div className="bg-gradient-to-r from-purple-900 via-purple-800 to-pink-900 text-white relative z-50">
      <div className="absolute inset-0 bg-black/20" />
      <div className="container mx-auto px-4 py-2.5 relative">
        <div className="flex items-center justify-center gap-4">
          <Gift className="w-4 h-4 text-purple-200" />
          <p className="text-sm md:text-base">
            <span className="hidden md:inline">🎉 Launch Offer:</span> First 100 users get 100 free videos!
            <Link to="/waitlist" className="ml-2 underline font-medium hover:text-purple-200 transition-colors">
              Join Waitlist
            </Link>
          </p>
          <button 
            onClick={() => setIsVisible(false)}
            className="text-white/80 hover:text-white transition-colors"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}