import React from 'react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { BookOpen, Video, Wand2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

function GuidesPage() {
  const navigate = useNavigate();

  const guides = [
    {
      icon: <BookOpen className="w-8 h-8" />,
      title: "Getting Started Guide",
      description: "Learn the basics of creating AI videos with our platform",
      readTime: "5 min read",
      path: "/guides/getting-started"
    },
    {
      icon: <Video className="w-8 h-8" />,
      title: "Viral Video Formula",
      description: "Discover the secret sauce behind viral content creation",
      readTime: "10 min read",
      path: "/guides/viral"
    },
    {
      icon: <Wand2 className="w-8 h-8" />,
      title: "Advanced Prompting",
      description: "Master the art of writing effective AI prompts",
      readTime: "15 min read",
      path: "/guides/prompting"
    }
  ];

  const handleGuideClick = (path: string) => {
    if (path === "/guides/viral") {
      navigate(path);
    } else {
      // For guides that aren't implemented yet, redirect to waitlist
      navigate('/waitlist');
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-20">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-6xl font-bold mb-6">Learn the Art of AI Video Creation</h1>
          <p className="text-xl text-gray-300">Master the tools and techniques to create viral content</p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {guides.map((guide) => (
            <div 
              key={guide.title} 
              className="bg-purple-900/20 rounded-2xl p-8 border border-purple-500/20 hover:border-purple-500/40 transition-all cursor-pointer group"
              onClick={() => handleGuideClick(guide.path)}
            >
              <div className="bg-purple-500/10 p-4 rounded-xl w-fit mb-6 group-hover:scale-110 transition-transform">
                {guide.icon}
              </div>
              <h3 className="text-2xl font-bold mb-2">{guide.title}</h3>
              <p className="text-gray-400 mb-4">{guide.description}</p>
              <div className="text-sm text-purple-400">{guide.readTime}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GuidesPage;