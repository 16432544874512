import React from 'react';
import { Navbar } from '../components/Navbar';
import { Footer } from '../components/Footer';
import { Gift } from 'lucide-react';
import { WaitlistForm } from '../components/WaitlistForm';

function WaitlistPage() {
  return (
    <>
      <Navbar />
      <div className="container mx-auto px-4 py-20">
        <div className="max-w-2xl mx-auto text-center">
          <div className="inline-flex items-center gap-2 bg-purple-500/10 px-4 py-2 rounded-full mb-8">
            <Gift className="w-5 h-5 text-purple-400" />
            <span className="text-purple-300">Launch Offer</span>
          </div>
          
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Join the Waitlist & Get
            <span className="block text-gradient">100 Free Videos</span>
          </h1>
          
          <p className="text-xl text-gray-300 mb-12">
            Be among the first 100 users to get exclusive access and generate 100 AI videos completely free!
          </p>

          <div className="max-w-md mx-auto bg-purple-900/20 p-8 rounded-2xl border border-purple-500/20">
            <WaitlistForm />
          </div>

          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-purple-900/20 p-6 rounded-xl border border-purple-500/20">
              <h3 className="font-semibold mb-2">Early Access</h3>
              <p className="text-gray-400">Be the first to try our AI video generator</p>
            </div>
            <div className="bg-purple-900/20 p-6 rounded-xl border border-purple-500/20">
              <h3 className="font-semibold mb-2">100 Free Videos</h3>
              <p className="text-gray-400">Generate up to 100 videos at no cost</p>
            </div>
            <div className="bg-purple-900/20 p-6 rounded-xl border border-purple-500/20">
              <h3 className="font-semibold mb-2">Priority Support</h3>
              <p className="text-gray-400">Get dedicated support from our team</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WaitlistPage;