import React from 'react';
import { Globe2, Users, Video, TrendingUp } from 'lucide-react';
import { ShortsPreview } from './ShortsPreview';
import { TypewriterText } from './TypewriterText';
import { WaitlistForm } from './WaitlistForm';

const metrics = [
  {
    icon: <Video className="w-5 h-5 text-teal-400" />,
    value: "125K+",
    label: "Videos Created"
  },
  {
    icon: <Globe2 className="w-5 h-5 text-blue-400" />,
    value: "18",
    label: "Countries"
  },
  {
    icon: <Users className="w-5 h-5 text-purple-400" />,
    value: "12K+",
    label: "Creators"
  },
  {
    icon: <TrendingUp className="w-5 h-5 text-pink-400" />,
    value: "125+",
    label: "100k+ View Videos"
  }
];

export function Hero() {
  return (
    <section className="relative min-h-[85vh] flex items-center">
      <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-purple-950/20" />
      
      <div className="container relative mx-auto px-4 md:px-6 py-12">
        <div className="flex flex-col md:flex-row justify-between items-start gap-8">
          {/* Empty Left Space (15%) */}
          <div className="hidden md:block md:w-[15%]" />
          
          {/* Main Content (50%) */}
          <div className="md:w-[50%] text-left">
            <h1 className="text-3xl md:text-5xl font-bold mb-4 text-white leading-tight tracking-tight">
              <span className="whitespace-nowrap">Create Viral{' '}
              <TypewriterText 
                words={['Shorts', 'Reels', 'TikToks']} 
                typingSpeed={150}
                delayBetweenWords={2000}
              /></span>
              <br />
              in Seconds
            </h1>

            <p className="text-lg text-gray-300 mb-8 leading-relaxed max-w-xl">
              Turn your ideas into engaging videos for TikTok, Instagram, and YouTube using AI. Just describe what you want, and we'll generate it.
            </p>

            {/* Trust Indicators */}
            <div className="inline-flex items-center gap-2 bg-purple-500/10 px-4 py-2 rounded-full">
              <div className="flex -space-x-2">
                {[1, 2, 3].map((i) => (
                  <img
                    key={i}
                    src={`https://i.pravatar.cc/32?img=${i}`}
                    alt="Creator"
                    className="w-6 h-6 rounded-full border-2 border-purple-900"
                  />
                ))}
              </div>
              <span className="text-purple-200">Trusted by 12K+ creators</span>
            </div>
          </div>

          {/* Waitlist Form (20%) */}
          <div className="md:w-[20%]">
            <div className="bg-purple-900/20 backdrop-blur-sm p-6 rounded-xl border border-purple-500/20">
              <WaitlistForm />
            </div>
          </div>

          {/* Empty Right Space (15%) */}
          <div className="hidden md:block md:w-[15%]" />
        </div>

        {/* Platform Metrics */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-3 max-w-4xl mx-auto mt-16">
          {metrics.map((metric, index) => (
            <div 
              key={index} 
              className="bg-purple-900/10 backdrop-blur-sm p-3 rounded-xl border border-purple-500/10 hover:border-purple-500/20 transition-all duration-300 text-center"
            >
              <div className="flex justify-center mb-1">{metric.icon}</div>
              <div className="text-lg font-bold text-gradient mb-0.5">{metric.value}</div>
              <div className="text-xs text-gray-400">{metric.label}</div>
            </div>
          ))}
        </div>
        
        <div className="relative mt-20">
          <ShortsPreview />
        </div>
      </div>
    </section>
  );
}