import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';
import { addToWaitlist } from '../lib/supabase';

export function WaitlistForm() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');
    
    try {
      await addToWaitlist(email);
      setStatus('success');
      setEmail('');
    } catch (error) {
      setStatus('error');
      setErrorMessage(error instanceof Error ? error.message : 'Something went wrong');
    }
  };

  if (status === 'success') {
    return (
      <div className="text-center">
        <div className="w-12 h-12 bg-green-500/20 rounded-full flex items-center justify-center mx-auto mb-3">
          <svg className="w-6 h-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <h3 className="text-lg font-semibold mb-2">You're on the list! 🎉</h3>
        <p className="text-sm text-gray-400">
          We'll notify you as soon as we launch.
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-3">
      <div className="space-y-1">
        <p className="text-sm leading-snug">
          Get 100 free AI videos! Limited to first 100 users.
        </p>
      </div>
      <div className="space-y-2">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="w-full px-4 py-2.5 rounded-lg bg-white/5 border border-purple-500/20 focus:border-purple-500 focus:ring-2 focus:ring-purple-500/20 outline-none text-sm"
          required
        />
        {status === 'error' && (
          <p className="text-xs text-red-400">{errorMessage}</p>
        )}
      </div>
      
      <button
        type="submit"
        disabled={status === 'loading'}
        className="w-full bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 px-6 py-2.5 rounded-lg text-sm font-medium flex items-center justify-center gap-2 transition-all duration-300 disabled:opacity-50"
      >
        {status === 'loading' ? (
          'Joining...'
        ) : (
          <>
            Join Waitlist
            <ArrowRight className="w-4 h-4" />
          </>
        )}
      </button>
      <p className="text-xs text-center text-gray-400">No credit card required</p>
    </form>
  );
}