import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import WaitlistPage from './pages/WaitlistPage';
import PricingPage from './pages/PricingPage';
import GuidesPage from './pages/GuidesPage';
import ToolsPage from './pages/ToolsPage';
import ViralFormulaGuide from './pages/guides/ViralFormulaGuide';
import VideoToShortsConverter from './pages/tools/VideoToShortsConverter';
import HashtagGenerator from './pages/tools/HashtagGenerator';

function ScrollManager() {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollTo) {
      const element = document.querySelector(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollManager />
      <div className="min-h-screen bg-gradient-to-b from-black to-purple-950 text-white">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/waitlist" element={<WaitlistPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/guides" element={<GuidesPage />} />
          <Route path="/guides/viral" element={<ViralFormulaGuide />} />
          <Route path="/tools" element={<ToolsPage />} />
          <Route path="/tools/video-to-shorts" element={<VideoToShortsConverter />} />
          <Route path="/tools/hashtag-generator" element={<HashtagGenerator />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;