import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navbar } from '../../components/Navbar';
import { Footer } from '../../components/Footer';
import { Video, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

function VideoToShortsConverter() {
  return (
    <>
      <Helmet>
        <title>Free Video to Shorts Converter AI | Genclips.ai</title>
        <meta 
          name="description" 
          content="Convert long videos into engaging shorts automatically with AI. Free online tool to create vertical videos for TikTok, Instagram Reels, and YouTube Shorts."
        />
        <meta name="keywords" content="video to shorts converter, shorts maker, vertical video converter, tiktok video converter" />
      </Helmet>

      <Navbar />
      <div className="container mx-auto px-4 py-20">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <div className="inline-flex items-center gap-2 bg-purple-500/10 px-4 py-2 rounded-full mb-6">
              <Video className="w-4 h-4 text-purple-400" />
              <span className="text-purple-300">Free Tool</span>
            </div>
            <h1 className="text-4xl md:text-6xl font-bold mb-6">Video to Shorts Converter</h1>
            <p className="text-xl text-gray-300">
              Convert any video into viral-worthy shorts automatically
            </p>
          </div>

          <div className="bg-purple-900/20 rounded-2xl p-8 mb-8">
            <Link 
              to="/waitlist"
              className="block w-full bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 p-8 rounded-xl text-center"
            >
              <h3 className="text-2xl font-bold mb-4">Drop your video here</h3>
              <p className="text-gray-300 mb-4">or click to browse</p>
              <div className="inline-flex items-center gap-2 text-sm font-medium">
                Try it now
                <ArrowRight className="w-4 h-4" />
              </div>
            </Link>
          </div>

          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Features</h3>
              <ul className="space-y-3">
                {[
                  'Automatic content detection',
                  'Smart scene selection',
                  'Vertical video optimization',
                  'Multiple aspect ratio support',
                  'AI-powered editing'
                ].map((feature, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <div className="w-1.5 h-1.5 rounded-full bg-purple-500" />
                    <span className="text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="space-y-4">
              <h3 className="text-xl font-semibold">How it works</h3>
              <ol className="space-y-3">
                {[
                  'Upload your long-form video',
                  'AI analyzes the best moments',
                  'Get multiple short clips',
                  'Download and share'
                ].map((step, index) => (
                  <li key={index} className="flex items-start gap-3">
                    <span className="text-sm text-purple-400">{index + 1}.</span>
                    <span className="text-gray-300">{step}</span>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default VideoToShortsConverter;