import React from 'react';
import { Instagram, Youtube, Twitter } from 'lucide-react';

const stats = [
  { number: "1M+", label: "Videos Generated" },
  { number: "100K+", label: "Active Creators" },
  { number: "50M+", label: "Views Generated" }
];

export function SocialProof() {
  return (
    <section className="py-24 container mx-auto px-6">
      <div className="text-center mb-16">
        <h2 className="text-2xl font-semibold mb-8">Trusted by content creators worldwide</h2>
        <div className="flex justify-center items-center gap-12 text-gray-400">
          <Instagram className="w-8 h-8" />
          <Youtube className="w-8 h-8" />
          <Twitter className="w-8 h-8" />
        </div>
      </div>
      
      <div className="grid md:grid-cols-3 gap-8">
        {stats.map((stat, index) => (
          <div key={index} className="text-center p-8 rounded-2xl bg-purple-950/30 border border-purple-800/30">
            <div className="text-4xl font-bold mb-2 text-gradient">{stat.number}</div>
            <div className="text-gray-400">{stat.label}</div>
          </div>
        ))}
      </div>
    </section>
  );
}