import React from 'react';
import { Play, Sparkles } from 'lucide-react';

const shorts = [
  {
    thumbnail: "https://images.unsplash.com/photo-1543610892-0b1f7e6d8ac1?fit=crop&w=450&h=800&q=80",
    views: "3.2M",
    title: "AI Fashion Model",
    aiPrompt: "Futuristic fashion show, neon lights",
    isAiGenerated: true,
    category: "Faceless Videos"
  },
  {
    thumbnail: "https://images.unsplash.com/photo-1578632767115-351597cf2477?fit=crop&w=450&h=800&q=80",
    views: "1.8M",
    title: "Anime Adventure",
    aiPrompt: "Japanese anime style, magical world",
    isAiGenerated: true,
    category: "Story Videos"
  },
  {
    thumbnail: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?fit=crop&w=450&h=800&q=80",
    views: "2.1M",
    title: "Digital Beauty",
    aiPrompt: "Ethereal portrait, glowing skin",
    isAiGenerated: false,
    category: "Faceless Videos"
  },
  {
    thumbnail: "https://images.unsplash.com/photo-1608889476561-6242cfdbf622?fit=crop&w=450&h=800&q=80",
    views: "892K",
    title: "Superhero Origin",
    aiPrompt: "Marvel-inspired hero, cosmic powers",
    isAiGenerated: true,
    category: "Story Videos"
  },
  {
    thumbnail: "https://images.unsplash.com/photo-1509023464722-18d996393ca8?fit=crop&w=450&h=800&q=80",
    views: "1.5M",
    title: "Historical Epic",
    aiPrompt: "Ancient civilization, epic battle scene",
    isAiGenerated: true,
    category: "Story Videos"
  }
];

export function ShortsPreview() {
  return (
    <div className="w-full overflow-hidden py-6">
      <div className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-black to-transparent z-10" />
      <div className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-black to-transparent z-10" />
      
      <div className="flex gap-4 animate-scroll">
        {[...shorts, ...shorts].map((short, index) => (
          <div 
            key={index} 
            className="relative flex-none w-[280px] rounded-2xl overflow-hidden aspect-[9/16] group"
          >
            <img 
              src={short.thumbnail} 
              alt={short.title}
              className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700 ease-out"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent group-hover:via-black/40 transition-colors duration-300" />
            
            {/* Category Badge */}
            <div className="absolute top-4 left-4">
              <div className="bg-purple-500/20 backdrop-blur-sm px-3 py-1.5 rounded-full flex items-center gap-1.5">
                <span className="text-xs text-purple-200">{short.category}</span>
              </div>
            </div>

            {/* AI Badge */}
            {short.isAiGenerated && (
              <div className="absolute top-14 left-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div className="bg-purple-500/20 backdrop-blur-sm px-3 py-1.5 rounded-full flex items-center gap-1.5">
                  <Sparkles className="w-3.5 h-3.5 text-purple-300" />
                  <span className="text-xs text-purple-200">AI Generated</span>
                </div>
              </div>
            )}

            {/* Content */}
            <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-2 group-hover:translate-y-0 transition-transform duration-300">
              <div className="flex items-center gap-2 text-sm mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <Play className="w-4 h-4 text-purple-400 animate-pulse" />
                <span>{short.views} views</span>
              </div>
              <h3 className="font-medium transform -translate-y-2 group-hover:translate-y-0 transition-transform duration-300">
                {short.title}
              </h3>
              <p className="text-xs text-purple-300/80 mt-1 opacity-0 group-hover:opacity-100 transition-all duration-300">
                Prompt: "{short.aiPrompt}"
              </p>
            </div>

            {/* Hover Effects */}
            <div className="absolute inset-0 bg-purple-600/20 opacity-0 group-hover:opacity-100 transition-all duration-300">
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,rgba(168,85,247,0.1),transparent_70%)]" />
            </div>

            {/* Play Button */}
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 scale-50 group-hover:scale-100">
              <div className="bg-purple-500/30 backdrop-blur-sm p-4 rounded-full">
                <Play className="w-8 h-8" fill="white" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}